import { useState } from "react";

import { useLocale, useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

import { search_attributes } from "@/components/molecules/MealCard";

import BoxListSliderElastic from "@/components/organisms/BoxListSliderElastic";

import useProductCalendar from "@/hooks/useProductCalendar";

import { Link } from "@/utils/navigation";
import getMealCurrentWeeks from "@/utils/shared/getMealCurrentWeeks";

type SanityMealBoxSectionProps = {
  section: any;
  className?: string;
  legacy?: boolean;
};

const SanityMealBoxSection = ({
  section,
  className,
  legacy = false,
}: SanityMealBoxSectionProps) => {
  const { getBoxPeriodLabel } = useProductCalendar();
  const periods = getMealCurrentWeeks();

  const [currentPeriod, setCurrentPeriod] = useState<string>(periods[0]);

  const locale = useLocale();
  const t = useTranslations(`components/SanityMealBoxSection`);

  const handleSelectPeriod = (period: string) => () => {
    setCurrentPeriod(period);
  };

  const NextWeeks = periods.map((period: string) => {
    const week = period.slice(-2);
    return (
      <Button
        size="xl"
        className={twMerge(
          `w-full text-left bg-white hover:bg-menthe-100 py-5 text-vert-700 font-semibold`,
          currentPeriod === period &&
            `bg-menthe-100 hover:bg-menthe-200 !text-choux-500`
        )}
        key={period}
        onClick={handleSelectPeriod(period)}
      >
        {t(`week-period`, {
          period: getBoxPeriodLabel(period),
        })}
      </Button>
    );
  });

  return (
    <div className={className}>
      <div className="block sm:flex pb-6 sm:space-x-6 space-y-1">
        {NextWeeks}
      </div>
      <BoxListSliderElastic
        legacy={legacy}
        settings={{
          sorting: {
            default: {
              field: `stats.sell-qty-${locale}`,
              order: `desc`,
            },
          },
          search_attributes: [`name`],
          result_attributes: search_attributes,
          facet_attributes: [
            {
              attribute: `brand`,
              field: `brand.name.keyword`,
              type: `string`,
            },
            {
              attribute: `categories_level_0`,
              field: `categories_level_0.keyword`,
              type: `string`,
            },
            {
              attribute: `categories_level_1`,
              field: `categories_level_1.keyword`,
              type: `string`,
            },
            {
              attribute: `categories_level_2`,
              field: `categories_level_2.keyword`,
              type: `string`,
            },
          ],
          filter_attributes: [
            {
              attribute: `availableWeeks`,
              field: `availableWeeks.keyword`,
              type: `string`,
            },
            { attribute: `hasVariant`, field: `hasVariant`, type: `string` },
            { attribute: `isOnline`, field: `isOnline`, type: `string` },
          ],
          /*
search_attributes: [`name`, `email`],
result_attributes: [`id`, `name`, `email`],
highlight_attributes: [`name`],
*/
        }}
        filters={`availableWeeks:${currentPeriod} AND hasVariant:true AND isOnline:true`}
        Action={
          <Link href="/box">
            <Button size="xl" color="menthe" variant="contained">
              {t(`button`)}
            </Button>
          </Link>
        }
      />
    </div>
  );
};

export default SanityMealBoxSection;
